@import "bootstrap/dist/css/bootstrap.min.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button,
textarea,
select {
    font: inherit;
}


.nav-pills {
  /* --bs-nav-pills-link-active-bg: #ffc107; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.after-content-none::after {
  content: none !important;
}

.before-content-none::before {
  content: none !important;
}

.bg-nav{
  background-color: #f0f0f0;
}

.cursor-pointer{
  cursor: pointer;
}
.animated-btn {
  position: relative;
  overflow: hidden;
  color: white;
  font-weight: bold;
  transition: all 0.3s ease;
}

.animated-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  transition: all 0.4s ease;
}

.animated-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.animated-btn:hover::before {
  left: 100%;
}
