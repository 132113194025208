.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* @keyframes pulse {
  0% {
    box-shadow: 0 0 5px rgba(72, 239, 128, 0.5),
      0 0 10px rgba(72, 239, 128, 0.4);
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 20px rgba(72, 239, 128, 0.8),
      0 0 30px rgba(72, 239, 128, 0.6);
    transform: scale(1.05);
  }
  100% {
    box-shadow: 0 0 5px rgba(72, 239, 128, 0.5),
      0 0 10px rgba(72, 239, 128, 0.4);
    transform: scale(1);
  }
} */

.animated-btn {
  position: relative;
  overflow: hidden;
  color: white;
  font-weight: bold;
  transition: all 0.3s ease;
  animation: pulse 2s infinite; /* Continuous pulsing animation */
}

.animated-btn:hover {
  transform: scale(1.1);
}

.animated-btn {
  position: relative;
  overflow: hidden;
  color: white;
  font-weight: bold;
  transition: all 0.3s ease;
}

.animated-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transition: all 0.4s ease;
}

.animated-btn:hover {
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.animated-btn:hover::before {
  left: 100%;
}

.App-link {
  color: #61dafb;
}
.custom-dropdown-container {
  position: relative; /* Ensure relative positioning for absolute children */
}

.custom-dropdown-menu {
  /* Ensure it appears above other elements */
  width: 100%; /* Full width of the container */
  max-height: 200px; /* Limit maximum height if needed */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
  position: absolute; /* Position the dropdown content */
  z-index: 1000;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-button:horizontal {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 242, 205);
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}

.blur {
  filter: blur(5px); /* Adjust the blur level as needed */
}
.loader-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
