.card {
  border-radius: var(--bs-border-radius);
  padding: 12px;
  margin: 0.5rem;
  margin-bottom: '50px' !important;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.button_row {
  display: flex;
  gap: 0.5rem;
  justify-content: start;
  padding-bottom: 0.5rem;
}

.grid_container {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  height: 100%;
  width: 100%;
  row-gap: 12px;
  column-gap: 16px;
}

.label {
  color: #707070;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  justify-content: end;
  align-items: center;
}

.value {
  color: #121212;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-inline: 0.5rem;
  min-width: 200px;
  width: 60%;
  gap: 1rem;
}

.divider {
  margin-block: 0.5rem;
  height: 2px;
  width: 100%;
  background: #ededed;
}

