.vendorNames:hover {
  background-color: #f0f0f0;
}
.vendorSearch {
  width: 60%;
  border-radius: 5px;
  outline: none;
  border: 1px solid gray;
  margin: 10px 0px 10px 0px;
  padding: 5px;
}
